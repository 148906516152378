var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./test1.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Fcdn.jsdelivr.net%2Fnpm%2Fbootstrap%403.3.7%2Fdist%2Fcss%2Fbootstrap.min.css&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports